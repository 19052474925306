<template>
  <v-container :style="{ padding: '0', marginTop: '12px' }">
    <v-row v-if="finalizedAccommodation">
      <v-col cols="12" :style="padding">
        <h2
          class="tw-text-left tw-font-figtree tw-text-22 tw-font-semibold tw-text-dark-green"
        >
          🌟 Your Stay!
        </h2>
        <p class="tw-text-left tw-text-base tw-text-light-grey tw-mb-6">
          Your crew’s accommodations have been decided! View the details below.
        </p>
        <div class="finalized-stay tw-shadow-custom tw-rounded-md tw-relative">
          <img
            v-if="!finalizedAccommodation.imageUrl"
            src="@/assets/svgs/destinations/destination-placeholder-img.svg"
            alt="Accommodation"
            class="tw-w-full"
          />
          <img
            v-if="finalizedAccommodation.imageUrl"
            :src="finalizedAccommodation.imageUrl"
            alt="Accommodation"
            class="tw-w-full"
          />
          <div class="finalized-stay-details tw-p-3">
            <!-- <p class="tw-text-j-red tw-text-left tw-text-xxs tw-uppercase">Airbnb</p> -->
            <h3
              class="tw-text-left tw-font-figtree tw-text-xs tw-font-semibold tw-text-dark-green"
            >
              {{ finalizedAccommodation.name }}
            </h3>
            <p
              class="tw-text-left tw-font-figtree tw-text-xs tw-font-regular tw-text-dark-green"
            >
              ${{ finalizedAccommodation.price / 100 }}
              {{
                finalizedAccommodation.priceType === price_types.TOTAL
                  ? "total"
                  : "per night"
              }}
              / ${{ costPerPerson / 100 }}
              per person
            </p>
            <p
              class="tw-text-left tw-font-figtree tw-text-xs tw-font-regular tw-text-dark-green"
            >
              {{
                finalizedAccommodation.address
                  ? finalizedAccommodation.address + ","
                  : ""
              }}
              {{ finalizedAccommodation.location }}
            </p>
            <p
              class="tw-text-left tw-font-figtree tw-text-xs tw-font-regular tw-text-dark-green tw-mt-5"
            >
              Check-in:
              {{
                finalizedAccommodation.startDate
                  ? getMonthAndDay(finalizedAccommodation.startDate) + ", "
                  : ""
              }}{{
                finalizedAccommodation.checkInTime
                  ? formatAMPM(finalizedAccommodation.checkInTime.slice(0, -3))
                  : ""
              }}
            </p>
            <div class="tw-flex tw-justify-between tw-items-center">
              <p
                class="tw-text-left tw-font-figtree tw-text-xs tw-font-regular tw-text-dark-green tw-mb-0"
              >
                Check-out:
                {{
                  finalizedAccommodation.endDate
                    ? getMonthAndDay(finalizedAccommodation.endDate) + ", "
                    : ""
                }}{{
                  finalizedAccommodation.checkOutTime
                    ? formatAMPM(
                        finalizedAccommodation.checkOutTime.slice(0, -3)
                      )
                    : ""
                }}
              </p>
              <a
                :href="getHrefLink(finalizedAccommodation.linkUrl)"
                target="_blank"
                class="tw-underline tw-text-xs tw-text-link-blue tw-cursor-pointer"
                v-if="finalizedAccommodation.linkUrl"
                >View details</a
              >
            </div>
          </div>
        </div>
        <v-btn
          x-large
          rounded
          block
          depressed
          class="j-btn tw-bg-chartreuse tw-mt-4 tw-tracking-normal"
          v-if="isOwner || isOrganizer"
          @click="
            goTo({
              name: 'AccommodationFinalize',
              params: { finalized: finalizedAccommodation }
            })
          "
          >Update Stay
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasDestination && !finalizedAccommodation">
      <v-col cols="12" :style="padding">
        <!-- <div class="tw-flex tw-justify-center">
          <img src="@/assets/svgs/accommodation/home.svg" alt="Home" />
        </div> -->
        <h2
          class="tw-text-left tw-font-figtree tw-text-lg tw-font-semibold tw-text-dark-green tw-mt-4"
        >
          Let’s Find a Stay
        </h2>
        <p class="tw-text-left tw-text-base tw-text-light-grey">
          Know a cool spot? Suggest a stay for the group to vote on.
        </p>
        <v-btn
          block
          rounded
          x-large
          color="secondary"
          large
          class="tw-mt-6 tw-tracking-normal"
          @click="goTo({ name: 'AccommodationCreate' })"
        >
          <v-icon>mdi-plus</v-icon>Suggest a stay
        </v-btn>
        <v-btn
          x-large
          rounded
          block
          depressed
          class="j-btn tw-bg-chartreuse tw-mt-4 tw-tracking-normal"
          v-if="isOwner || isOrganizer"
          @click="goTo({ name: 'AccommodationFinalize' })"
          >Finalize Your Stay
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!hasDestination">
      <v-col cols="12" :style="padding">
        <div class="tw-flex tw-justify-center">
          <img src="@/assets/svgs/accommodation/home.svg" alt="Home" />
        </div>
        <h2
          class="tw-text-center tw-font-figtree tw-text-lg tw-font-semibold tw-text-dark-green tw-mt-4 tw-max-w-xs tw-mx-auto"
        >
          Your trip needs a destination
        </h2>
        <p
          class="tw-text-center tw-text-base tw-text-light-grey tw-mt-2 tw-max-w-xs tw-mx-auto"
        >
          Plan where you're going first & come back to stays later.
        </p>
        <v-btn
          block
          rounded
          x-large
          color="secondary"
          large
          class="tw-mt-6 tw-tracking-normal"
          @click="
            goTo({
              name: 'DestinationsRoute',
              params: { id: $route.params.id }
            })
          "
        >
          Go to Destinations
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="tw-mt-12" v-if="false">
      <v-col cols="12" :style="padding">
        <p class="tw-text-left tw-text-xs tw-text-light-grey tw-mb-2">
          We earn a small amount when you buy through our links
        </p>
        <div class="tw-w-full tw-flex">
          <a
            href="https://prf.hn/click/camref:1101l3BN3k/pubref:letsjetty/ar:app"
            target="_blank"
            rel="noopener noreferrer"
            class="tw-w-1/2 tw-mr-2"
          >
            <button
              class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
              type="button"
            >
              <span class="tw-text-left tw-text-dark-green tw-font-bold"
                >Expedia</span
              >
              <img
                class="tw-rounded-full"
                src="@/assets/svgs/arrow-right-dark-background.svg"
                alt="Go"
              />
            </button>
          </a>
          <a
            href="https://prf.hn/click/camref:1100l3BNe3/pubref:letsjetty/ar:app"
            target="_blank"
            rel="noopener noreferrer"
            class="tw-w-1/2 tw-ml-2"
          >
            <button
              class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
              type="button"
            >
              <span class="tw-text-left tw-text-dark-green tw-font-bold"
                >VRBO</span
              >
              <img
                class="tw-rounded-full"
                src="@/assets/svgs/arrow-right-dark-background.svg"
                alt="Go"
              />
            </button>
          </a>
        </div>
      </v-col>
    </v-row>

    <v-row :style="padding" class="mt-10">
      <v-col cols="12">
        <h4 class="tw-text-charcoal tw-text-sm text-left tw-font-bold">
          FROM OUR PARTNERS
        </h4>
        <p class="text-left text-muted mb-0">
          Partner links may result in us earning a commission or fee at no additional cost to you.
        </p>
      </v-col>
    </v-row>
    <v-row :style="padding">
      <faye-button link="https://www.withfaye.com/?utm_medium=gd-partnerships&utm_source=letsjetty&utm_campaign=general_us&utm_content=finalizedstay" />
    </v-row>

    <v-row v-if="trip && postAccommodations.length > 0">
      <v-col cols="12">
        <div class="tw-flex tw-justify-between tw-items-center tw-px-8">
          <h2
            class="tw-text-left tw-font-figtree tw-text-18 tw-font-bold tw-text-dark-green tw-my-6"
          >
            Crew Suggestions
          </h2>
          <v-menu offset-y bottom left>
            <template v-slot:activator="{ on }">
              <div
                class="tw-flex tw-flex-row tw-items-center tw-justify-start"
                v-on="on"
              >
                <span
                  class="tw-text-dark-green tw-border-b tw-border-solid tw-border-dark-green tw-font-bold tw-inline-block tw-text-xxs tw-mr-2 tw-cursor-pointer"
                  >SORT BY</span
                >
                <img
                  src="@/assets/svgs/accommodation/chevron.svg"
                  alt="Chevron"
                />
              </div>
            </template>
            <v-list class="tw-text-xs tw-text-charcoal">
              <v-list-item
                class="tw-cursor-pointer"
                @click="fetchAndSortAccommodations('createdAt')"
              >
                Most Recent</v-list-item
              >
              <v-list-item
                class="tw-cursor-pointer"
                @click="fetchAndSortByLikes"
                >Top Posts</v-list-item
              >
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <div
        class="tw-bg-separator-grey tw-w-full tw-h-2"
        v-if="postAccommodations && postAccommodations.length > 0"
      ></div>
      <v-col cols="12" :style="{ padding: '0' }">
        <Post
          v-for="accommodation in postAccommodations"
          :key="accommodation.uuid"
          :accommodation="accommodation"
          :user="sessionUser"
          :is-trip-owner="isOwner || isOrganizer"
          @accommodation-updated="updateAccommodation($event)"
          @delete="toggleDialog($event)"
        />
      </v-col>
    </v-row>
    <DeleteDialog
      :is-visible="isVisible"
      :uuid="uuidToDelete"
      @cancel="toggleDialog"
      @close="toggleDialog"
    />
  </v-container>
</template>
<script>
import { PRICE_TYPES } from "@/enums/price-types.js";
import Post from "@/components/accommodation/Post.vue";
import DeleteDialog from "@/components/accommodation/DeleteDialog.vue";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import { UrlHelpers } from "@/mixins/UrlHelpers.js";
import FayeButton from '../partners/FayeButton.vue';
export default {
  name: "AccommodationList",
  mixins: [FormattedDateRange, UrlHelpers],
  components: {
    Post,
    DeleteDialog,
    FayeButton
  },
  data() {
    return {
      postAccommodations: [],
      finalizedAccommodation: null,
      isVisible: false,
      uuidToDelete: null,
      price_types: PRICE_TYPES,
      padding: {
        paddingLeft: "40px",
        paddingRight: "40px",
        paddingTop: "0",
        paddingBottom: "0"
      }
    };
  },
  computed: {
    sessionUser() {
      return this.$store.state.auth.user;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    isOwner() {
      return this.trip && this.trip.ownerId === this.sessionUser.id;
    },
    isOrganizer() {
      if (
        !this.trip ||
        !this.sessionUser.trips ||
        this.sessionUser.trips.length === 0
      )
        return false;
      let currentTrip = this.sessionUser.trips.find(
        (t) => t.id === this.trip.id
      );
      if (!currentTrip) return false;
      return currentTrip.trip_invite.roles.includes("owner");
    },
    hasDestination() {
      return (
        this.trip && this.trip.destinations && this.trip.destinations.length > 0
      );
    },
    costPerPerson() {
      let acceptedUsers = this.trip.users.filter(
        (user) => user.trip_invite.status === "accepted"
      ).length;
      if (this.finalizedAccommodation) {
        const cost = this.finalizedAccommodation.price / acceptedUsers;
        return Math.round(cost);
      }
      return 0;
    }
  },
  methods: {
    goTo(route) {
      this.$router.push(route);
    },
    formatAMPM(time) {
      let hours = time.split(":")[0];
      let minutes = time.split(":")[1];
      let ampm = hours >= 12 ? "PM" : "AM";

      hours = hours % 12;
      hours = hours ? hours : 12;
      if (minutes !== "00") {
        minutes = minutes < 10 ? "0" + minutes : minutes;
      }
      let strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    toggleDialog(uuid) {
      this.uuidToDelete = uuid || null;
      this.isVisible = !this.isVisible;
      if (!uuid) this.getAccommodations();
    },
    async getAccommodations() {
      const res = await this.$store.dispatch("post-accommodation/find", {
        query: {
          tripId: this.$route.params.id,
          $sort: {
            createdAt: -1
          }
        }
      });
      this.postAccommodations = res.data;

      const finalizedRes = await this.$store.dispatch("accommodation/find", {
        query: {
          tripId: this.$route.params.id
        }
      });
      if (finalizedRes.data.length > 0)
        this.finalizedAccommodation = finalizedRes.data[0];
    },
    async fetchAndSortAccommodations(sortField) {
      const res = await this.$store.dispatch("post-accommodation/find", {
        query: {
          tripId: this.$route.params.id,
          $sort: {
            [sortField]: -1
          }
        }
      });
      this.postAccommodations = res.data;
    },
    async getTrip() {
      await this.$store.dispatch("trip/get", [
        this.$route.params.id,
        {
          query: {
            include: "destination,users,survey,trip_invitation"
          }
        }
      ]);
    },
    async fetchAndSortByLikes() {
      const res = await this.$store.dispatch("post-accommodation/find", {
        query: {
          tripId: this.$route.params.id
        }
      });
      const accommodations = res.data;

      accommodations.sort((a, b) => {
        const likesA = a.post_likes.length;
        const likesB = b.post_likes.length;

        return likesB - likesA;
      });
      this.postAccommodations = accommodations;
    },
    updateAccommodation(updatedAccommodation) {
      const index = this.postAccommodations.findIndex(
        (acc) => acc.uuid === updatedAccommodation.uuid
      );
      if (index !== -1)
        this.$set(this.postAccommodations, index, updatedAccommodation);
    }
  },
  beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Places To Stay",
      bgColor: "#D6BCFF",
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });

    if (sessionStorage.getItem("itinerary")) {
      this.$store.commit("meta/setHeader", {
        ...this.$store.state.meta.header,
        tripHubButton: false,
        showBackButton: true
      });
    }

    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: false,
      text: null,
      method: null
    });
    if (!this.trip) {
      this.getTrip();
    }
    this.getAccommodations();
  },
  destroyed() {
    sessionStorage.removeItem("itinerary");
  }
};
</script>
