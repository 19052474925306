<template>
  <div class="upgrade-trip-purchase-view tw-mb-5">
    <v-container v-if="loading">
      <v-row>
        <v-col>
          <map-icon-loader />
        </v-col>
      </v-row>
    </v-container>

    <v-container class="pt-0" v-if="!loading && wallet && trip">
      <v-row>
        <v-col>
          <v-carousel
            class="perks-carousel"
            hide-delimiter-background
            :show-arrows="false"
            dark
            height="auto"
          >
            <v-carousel-item>
              <v-sheet height="325">
                <v-col class="tw-text-left px-10 pt-10 pb-10">
                  <img
                    src="@/assets/svgs/premium/blue-diamond-solo.svg"
                    class="tw-mb-4"
                    width="35"
                  />
                  <h3>Totally Worth It!</h3>
                  <p class="tw-text-l-grey">
                    “Seriously, the price is totally worth not having to put together my own excel sheets and soo many emails to plan a trip. Thank you for making this so easy!” <br />- Jill P.
                  </p>
                </v-col>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet height="325">
                <v-col class="tw-text-left px-10 pt-10 pb-10">
                  <img
                    src="@/assets/svgs/upgrade/custom-invitation.svg"
                    class="tw-mb-4"
                    width="40"
                  />
                  <h3>Fun Trip Invitations</h3>
                  <p class="tw-text-l-grey">
                    Get your friends to say yes to the trip! Upgrade to unlock a variety of stunning, themed invitation designs to match your trip’s vibe. Cue instant group hype!
                  </p>
                </v-col>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet height="325">
                <v-col class="tw-text-left px-10 pt-10 pb-10">
                  <img
                    src="@/assets/svgs/upgrade/unlimited-notes.svg"
                    class="tw-mb-4"
                    width="40"
                  />
                  <h3>Unlimited Notes &amp; Surveys</h3>
                  <p class="tw-text-l-grey">
                    Easily plan the ultimate trip using surveys to gather everyone's input and shared checklists to track tasks—making sure nothing gets missed and everyone stays organized!
                  </p>
                </v-col>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet height="325">
                <v-col class="tw-text-left px-10 pt-10 pb-10">
                  <img
                    src="@/assets/svgs/upgrade/be-the-first-trophy.svg"
                    class="tw-mb-4"
                    width="40"
                  />
                  <h3>Member Exclusives</h3>
                  <p class="tw-text-l-grey">
                    Be the first to access exciting new premium features before anyone else! Each upgrade directly supports the build of new tools &amp; features to make trip planning effortless.
                  </p>
                </v-col>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h2>Choose your package</h2>
        </v-col>
      </v-row>

      <v-row v-if="isProductFindPending">
        <v-col>
          <map-icon-loader />
        </v-col>
      </v-row>

      <v-row v-if="!isProductFindPending && products.length > 0">
        <v-col>
          <div class="package-select">
            <div v-for="(product, i) in products" :key="product.id">
              <div
                class="package-select-item"
                @click="selectedPackageIndex = i"
                :class="{
                  'package-select-item--active': selectedPackageIndex === i
                }"
              >
                <div class="package-select-item-text">
                  <p class="package-name">{{ product.name }}</p>
                  <p class="package-details">
                    {{ product.metadata?.description }}
                  </p>
                </div>
                <div class="package-select-item-radio">
                  <div class="package-select-item-radio-circle"></div>
                </div>
                <div
                  v-if="product.metadata?.best_value"
                  class="package-select-item-chip"
                >
                  Best Value
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            class="j-premium spacing-override"
            @click="goToCheckout"
            :loading="checkoutLoading"
            :disabled="checkoutLoading"
            block
            depressed
            x-large
            rounded
            dark
            >Continue</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <v-btn
          :to="{ name: 'TripViewRoute', params: { id: trip.id } }"
          class="spacing-override"
          block
          plain
          link
        >
          Not today. Take me back.
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MapIconLoader from "../components/loaders/MapIconLoader.vue";

export default {
  name: "UpgradeTripPurchaseView",

  components: { MapIconLoader },

  data() {
    return {
      loading: false,
      selectedPackageIndex: 0,
      checkoutLoading: false
    };
  },

  computed: {
    ...mapGetters({
      products: "product/list"
    }),
    selectedPackage() {
      return this.products[this.selectedPackageIndex];
    },
    sessionUser() {
      return this.$store.state.auth.user;
    },
    wallet() {
      return this.sessionUser.user_wallet;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    isProductFindPending() {
      return this.$store.state.product.isFindPending;
    }
  },

  methods: {
    async goToCheckout() {
      const { stripeCustomerId, uuid } = this.sessionUser;

      this.checkoutLoading = true;

      await this.$store
        .dispatch("checkout-session/create", {
          customer: stripeCustomerId,
          userUUID: uuid,
          return_url: `${process.env.VUE_APP_BASE_URL}/app/trips/${this.trip.id}/upgrade/confirm`,
          lineItems: [
            {
              price: this.selectedPackage.default_price,
              quantity: 1
            }
          ]
        })
        .then((checkoutSession) => {
          if (checkoutSession.id) {
            this.$router.push({
              name: "UpgradeTripCheckoutRoute",
              params: { id: this.trip.id, checkoutId: checkoutSession.id }
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.checkoutLoading = false;
        });
    }
  },

  async beforeMount() {
    this.loading = true;

    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Upgrade Trip",
      bgColor: "#ffffff",
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      startDate: null,
      showBackButton: true,
      endDate: null
    });

    // Get fresh user session data to check for wallet and stripe ID. This potentially was causing duplicate wallet entries.
    await this.$store.dispatch("users/get", this.sessionUser.id);

    // Check if user has a wallet
    if (!this.sessionUser.user_wallet) {
      await this.$store.dispatch("wallet/create", {
        userId: this.sessionUser.id
      });
    }

    // Check if user has a stripe customer record
    if (!this.sessionUser.stripeCustomerId) {
      await this.$store.dispatch("customer/create", {
        userId: this.sessionUser.id,
        name: this.sessionUser.fullName,
        email: this.sessionUser.email
      });
    }

    // Refresh user data in auth session
    await this.$store.dispatch("users/get", this.sessionUser.id);

    // Dispatch the action to get trip details
    await this.$store.dispatch("trip/get", this.$route.params.id);

    // Fetch stripe products for in app display
    await this.$store.dispatch("product/find");

    this.loading = false;
  },
  mounted() {},
  beforeUnmount() {}
};
</script>

<style lang="scss">
.upgrade-trip-purchase-view {
  .spacing-override {
    letter-spacing: normal;
  }

  h2 {
    font-family: Figtree;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: left;
  }

  h3 {
    font-family: Figtree;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .j-premium-text {
    font-weight: 600;
    color: #1743bb;
  }

  .perks-carousel {
    overflow: visible;

    h2 {
      font-family: Figtree;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.02em;
      text-align: left;
    }
  
    h3 {
      font-family: Figtree;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 6px;
      letter-spacing: -0.02em;
      text-align: left;
    }
  
    p {
      font-size: 16px;
    }

    .v-responsive {
      overflow: visible;
    }

    .v-sheet {
      border-radius: 6px;
      box-shadow: 1px 6px 19px 0px rgba(0, 0, 0, 0.11);
    }

    .v-carousel__controls {
      position: relative;
      bottom: -10px;

      .v-btn > .v-btn__content .v-icon {
        color: #d9d9d9;
      }

      .v-btn.v-btn--active > .v-btn__content .v-icon {
        color: #585858;
      }
    }
  }

  .package-select {
    .package-select-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border: 1px solid #dedede;
      border-radius: 9px;
      padding: 25px;
      margin-bottom: 25px;
      transition: background linear 200ms;

      &:hover {
        cursor: pointer;
        background-color: #f2f2f2;
      }

      &.package-select-item--active {
        background-color: #ffffff;
        border: 2px solid #1743bb;
        padding: 24px;

        .package-select-item-chip {
          right: 29px;
          top: -13px;
        }

        .package-select-item-radio-circle {
          @apply j-premium;
          border: 2px solid #ffffff;
          box-shadow: 0px 0px 4px #1743bb;
        }
      }
    }

    .package-select-item-text {
      width: 85%;
      text-align: left;

      p {
        padding: 0;
        margin: 0;
      }

      .package-name {
        font-weight: bold;
      }
    }

    .package-select-item-radio {
      .package-select-item-radio-circle {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 1px solid #dedede;
      }
    }

    .package-select-item-chip {
      @apply j-premium;
      position: absolute;
      top: -12px;
      right: 30px;
      padding: 5px 20px;
      border-radius: 17px;
      text-transform: uppercase;
      font-size: 9px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
</style>
