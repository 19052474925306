<template>
  <div class="tw-px-6" v-if="note">
    <v-row v-if="isEditing">
      <v-col cols="12" class="tw-mt-4 tw-mb-8">
        <h3
          class="tw-text-left tw-text-dark-green tw-font-figtree tw-font-semibold tw-text-22 tw-mb-2"
        >
          Edit Note
        </h3>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="save">
            <validation-provider v-slot="{ errors }">
              <j-text-field
                color="secondary"
                v-model="note.title"
                type="text"
                :error-messages="errors"
                label="Note Title*"
                class="tw-mt-6"
                customClasses="tw-text-left"
              />
            </validation-provider>
            <span class="j-text-field-label tw-my-5">Content</span>
            <!-- Integrate Quill Editor -->
            <vue-editor
              v-model="note.body"
              ref="editor"
              :editor-toolbar="customToolbar"
            ></vue-editor>
            <!-- End of Quill Editor -->
            <span class="tw-hidden">{{ invalid }}</span>
          </v-form>
        </validation-observer>
        <p
          class="tw-text-left tw-underline tw-cursor-pointer tw-font-semibold tw-text-18 tw-mt-8"
          @click="showDeleteNote = true"
          v-if="isOwner || isOrganizer"
        >
          Delete Note
        </p>
      </v-col>
    </v-row>
    <v-row v-else>
      <div
        class="tw-w-full tw-max-w-460 tw-text-right tw-fixed tw-bottom-32"
        v-if="!note.isLocked"
      >
        <v-btn class="tw-bg-light-blue tw-h-12 tw-mr-12" @click="editNote()">
          <img src="@/assets/svgs/edit-floating.svg" alt="Edit" />
        </v-btn>
      </div>
      <v-col cols="12" class="tw-mt-4 tw-mb-8">
        <h3
          class="tw-text-left tw-text-dark-green tw-font-figtree tw-font-semibold tw-text-22 tw-mb-2"
        >
          {{ note.title ? note.title : "Note Title" }}
        </h3>

        <div class="tw-text-left tw-mt-3" v-if="note.isLocked">
          <div class="tw-flex tw-items-center tw-justify-start">
            <p class="tw-text-light-blue tw-font-semibold tw-m-0 tw-mr-2">
              View Only
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              class="tw-cursor-pointer"
              @click="showInfoModal = !showInfoModal"
            >
              <path
                d="M6.3 3.5H7.7V4.9H6.3V3.5ZM6.3 6.3H7.7V10.5H6.3V6.3ZM7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 12.6C3.913 12.6 1.4 10.087 1.4 7C1.4 3.913 3.913 1.4 7 1.4C10.087 1.4 12.6 3.913 12.6 7C12.6 10.087 10.087 12.6 7 12.6Z"
                fill="#00ACF5"
              />
            </svg>
          </div>
          <p class="tw-m-0 tw-text-light-grey">
            This note is being edited by
            {{ computedLockedByName }}
          </p>
        </div>

        <hr class="tw-my-6" />

        <div
          class="content tw-text-left"
          v-html="note.body"
          @click="contentClicked($event)"
        ></div>

        <hr class="tw-my-6" />
        <p class="tw-m-0 tw-mb-2 tw-block tw-text-left tw-font-bold">
          Last updated:
          <span class="tw-text-light-grey tw-font-normal">{{
            getNoteSubtitle(note)
          }}</span>
        </p>
        <p
          class="tw-m-0 tw-mb-2 tw-block tw-text-left tw-font-bold"
          v-if="computedUpdatedByName.length > 0"
        >
          Updated by:
          <span class="tw-text-light-grey tw-font-normal">{{
            computedUpdatedByName
          }}</span>
        </p>
        <p
          class="tw-m-0 tw-block tw-text-left tw-font-bold"
          v-if="computedAuthorName.length > 0"
        >
          Owner:
          <span class="tw-text-light-grey tw-font-normal">{{
            computedAuthorName
          }}</span>
        </p>
      </v-col>
    </v-row>
    <v-dialog v-model="showDeleteNote" max-width="400">
      <v-card>
        <v-card-title
          class="tw-font-bebas-neue tw-text-uppercase tw-text-charcoal"
          >Delete shared Note?</v-card-title
        >
        <v-card-text class="tw-text-base tw-text-left"
          >This note will be deleted from all organizers and invitees in the
          trip. This cannot be undone.</v-card-text
        >
        <v-card-actions class="tw-justify-end">
          <button
            class="btn-tertiary tw-mx-4 tw-tracking-normal"
            @click="showDeleteNote = false"
          >
            Cancel
          </button>
          <v-btn
            rounded
            color="primary"
            class="tw-px-4 tw-tracking-normal"
            @click="deleteNote()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showInfoModal"
      content-class="tw-rounded-3xl"
      max-width="400"
    >
      <v-card class="tw-p-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          class="tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer"
          @click="showInfoModal = false"
        >
          <circle cx="11.1523" cy="10.9697" r="9.82422" stroke="#203848" />
          <path
            d="M14.9703 7.91012L14.1999 7.13965L11.1453 10.1942L8.09078 7.13965L7.32031 7.91012L10.3749 10.9647L7.32031 14.0192L8.09078 14.7897L11.1453 11.7351L14.1999 14.7897L14.9703 14.0192L11.9158 10.9647L14.9703 7.91012Z"
            fill="#203848"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="39"
          height="39"
          viewBox="0 0 39 39"
          fill="none"
        >
          <path
            d="M31.8115 38.574H7.55254C3.84929 38.574 0.84375 35.5685 0.84375 31.8652V7.60625C0.84375 3.903 3.84929 0.897461 7.55254 0.897461H19.682C20.5408 0.897461 21.2921 1.59518 21.2921 2.50757C21.2921 3.3663 20.5944 4.11768 19.682 4.11768H7.55254C5.62041 4.11768 4.06397 5.67412 4.06397 7.60625V31.8652C4.06397 33.7974 5.62041 35.3538 7.55254 35.3538H31.8115C33.7437 35.3538 35.3001 33.7974 35.3001 31.8652V19.7357C35.3001 18.877 35.9978 18.1256 36.9102 18.1256C37.7689 18.1256 38.5203 18.8233 38.5203 19.7357V31.8652C38.5203 35.5685 35.5148 38.574 31.8115 38.574Z"
            fill="#56CBFE"
          />
          <path
            d="M11.7898 29.2354C10.9311 29.2354 10.1797 28.5377 10.1797 27.6253C10.1797 27.4643 10.1797 27.3569 10.2334 27.2496L12.0581 20.1114C12.1118 19.8431 12.2728 19.5747 12.4875 19.36L30.4671 1.38049C31.1111 0.73645 32.0772 0.73645 32.7212 1.38049L38.0346 6.69385C38.6786 7.3379 38.6786 8.30396 38.0346 8.94801L20.055 26.9276C19.8403 27.1422 19.572 27.2496 19.3036 27.3569L12.1655 29.1817C12.0581 29.2354 11.8971 29.2354 11.7898 29.2354ZM15.01 21.3458L13.9366 25.4785L18.0692 24.4051L34.6534 7.82093L31.5405 4.70805L15.01 21.3458Z"
            fill="#56CBFE"
          />
          <path
            d="M32.3516 13.9921C31.9223 13.9921 31.5466 13.8311 31.2246 13.5091L25.9112 8.19574C25.3208 7.5517 25.3745 6.53196 26.0185 5.94159C26.6089 5.40489 27.5213 5.40489 28.1654 5.94159L33.4787 11.255C34.1228 11.899 34.0691 12.9187 33.4787 13.5091C33.1567 13.8311 32.7273 13.9921 32.3516 13.9921Z"
            fill="#56CBFE"
          />
        </svg>
        <p class="tw-text-left tw-text-22 tw-my-2 tw-font-semibold">
          How to Edit
        </p>
        <p class="tw-text-left tw-text-light-grey tw-text-sm tw-m-0">
          Ask the editor to finish editing or check back in to make edits. Two
          people cannot edit at the same time.
        </p>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCheckedModal"
      content-class="tw-rounded"
      max-width="300"
    >
      <v-card class="tw-py-5 tw-px-7">
        <p class="tw-text-left tw-m-0 tw-font-semibold tw-text-light-blue">
          Read-Only
        </p>
        <p class="tw-text-left tw-text-light-grey tw-text-sm tw-m-0">
          Click the Edit feature in the bottom right to check off item.
        </p>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
//import { DateTime } from "luxon";
import { VueEditor } from "vue2-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: "NoteCreate",
  mixins: [FormattedDateRange],
  components: {
    VueEditor
  },
  data() {
    return {
      note: null,
      customToolbar: [
        [{ header: [1, 2, 3, 4, false] }],
        ["bold", "italic", "underline"],
        ["link"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }]
      ],
      isEditing: false,
      showDeleteNote: false,
      showInfoModal: false,
      showCheckedModal: false,
      authorName: "",
      updatedByName: "",
      lockedByName: ""
      // More quill editor options
      /*[{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{ size: [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check'}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']*/
    };
  },
  watch: {
    enable: function (val) {
      if (val) {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: false
        });
      } else {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true
        });
      }
    }
  },
  computed: {
    computedAuthorName() {
      return `${this.note.author.firstName} ${this.note.author.lastName}`;
    },
    computedUpdatedByName() {
      return `${this.note.lastEditedByUser.firstName} ${this.note.lastEditedByUser.lastName}`;
    },
    computedLockedByName() {
      return `${this.note.isLockedByUser.firstName} ${this.note.isLockedByUser.lastName}`;
    },
    enable() {
      return (
        this.note && this.note.title.length > 0 && this.note.body.length > 0
      );
    },
    sessionUser() {
      return this.$store.state.auth.user;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    isOrganizer() {
      if (
        !this.trip ||
        !this.sessionUser.trips ||
        this.sessionUser.trips.length === 0
      )
        return false;
      let currentTrip = this.sessionUser.trips.find(
        (t) => t.id === this.trip.id
      );
      if (!currentTrip) return false;
      return currentTrip.trip_invite.roles.includes("owner");
    },
    isOwner() {
      return this.trip?.ownerId === this.sessionUser.id;
    }
  },
  methods: {
    contentClicked(e) {
      if (e.target.parentNode.dataset.checked) {
        this.showCheckedModal = true;
      }
    },
    getNoteSubtitle(note) {
      return `${this.getFormattedDate(note.updatedAt)}`;
    },
    async save() {
      if (!this.trip) {
        await this.$store.dispatch("trip/get", [
          this.$route.params.id,
          {
            query: {
              include: "destination,users,survey,trip_invitation"
            }
          }
        ]);
      }
      try {
        await this.$store.dispatch("note/patch", [
          this.note.id,
          {
            ...this.note,
            isLocked: false,
            isLockedBy: null
          }
        ]);

        this.isEditing = false;
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          button: false
        });

        this.getData();
      } catch (error) {
        console.error("Error patching note", error);
      }
    },
    async getData() {
      try {
        const note = await this.$store.dispatch("note/find", {
          query: {
            id: this.$route.params.noteId
          }
        });
        if (!note) {
          this.$router.push({
            name: "NotesListRoute"
          });
        }
        this.note = note.data[0];
      } catch {
        this.$router.push({
          name: "NotesListRoute"
        });
      }
    },
    async deleteNote() {
      await this.$store.dispatch("note/remove", this.note.id);
      this.$store.commit("note/clearAll", this.$store.state["note"]);
      this.showDeleteNote = false;
      this.$router.push({ name: "NotesListRoute" });
    },
    activateDeleteNoteDialog() {
      this.showDeleteNote = true;
    },
    async editNote() {
      try {
        await this.$store.dispatch("note/patch", [
          this.note.id,
          {
            ...this.note,
            isLocked: true,
            isLockedBy: this.sessionUser.id
          }
        ]);
        this.isEditing = false;
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          button: false
        });
      } catch (error) {
        console.error("Error patching note", error);
      }

      this.isEditing = true;
      this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
        button: true,
        text: "Save",
        isDisabled: false,
        method: this.save,
        bgColor: "tw-bg-chartreuse"
      });
    },
    async accidentalExitUnlock() {
      //accidental closing remove locked by
      if (this.note.isLockedBy && this.note.isLockedBy === this.sessionUser.id) {
        await this.$store.dispatch("note/patch", [
          this.note.id,
          {
            ...this.note,
            isLocked: false,
            isLockedBy: null
          }
        ]);
      }
    }
  },
  async beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Notes",
      bgColor: "#56CBFE",
      fontColor: "#fff",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });

    await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation"
        }
      }
    ]);

    await this.getData();

    await this.accidentalExitUnlock()

    window.addEventListener("beforeunload", this.accidentalExitUnlock);
  },
  async destroyed() {
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: false
    });

    //accidental closing remove locked by
    await this.accidentalExitUnlock()
  }
};
</script>

<style>
.ql-toolbar {
  border-radius: 4px;
  border: 1px solid #f5f5f5 !important;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
}

.ql-toolbar.ql-snow .ql-formats {
  text-align: left;
  margin-right: 0 !important;
  border-right: 1px solid lightgrey;

  &:last-child {
    border-right: 0;
  }

  @media screen and (min-width: 1023px) {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.ql-container {
  border: none !important;
  border-radius: 8px;
  background: #f2f2f2;
  margin-top: 20px;
}

ul[data-checked="true"] li,
ul[data-checked="false"] li,
.ql-editor ul[data-checked="true"] li,
.ql-editor ul[data-checked="false"] li {
  display: flex;
  list-style-type: none;
  margin-left: -0.3rem;
  @apply tw-font-semibold;
  overflow-wrap: anywhere;
  white-space: normal;
}

ul[data-checked="false"] > li:before,
ul[data-checked="true"] > li:before,
.ql-editor ul[data-checked="true"] li,
.ql-editor ul[data-checked="false"] li {
  cursor: pointer;
  margin-bottom: 16px;
  margin-top: 1px;
}

.ql-editor ul[data-checked="true"] li,
.ql-editor ul[data-checked="false"] li {
  margin-bottom: 0px;
}

ul[data-checked="false"] > li:before,
.ql-editor ul[data-checked="false"] li:before {
  content: "\00a0";
  display: inline-block;
  width: 22px;
  min-width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #000;
  margin-right: 5px;
}

.ql-editor ul[data-checked="false"] li:before {
  margin-left: -1.4rem;
  margin-top: -3px;
}

ul[data-checked="true"] > li:before,
.ql-editor ul[data-checked="true"] li:before {
  content: "\00a0";
  display: inline-block;
  width: 22px;
  min-width: 22px;
  height: 22px;
  background-color: #56cbfe;
  color: white;
  text-align: center;
  border-radius: 50%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><circle cx="12" cy="12" r="12" fill="%2356cbfe"/><polyline points="6 12 10 16 18 8" stroke="white" stroke-width="2" fill="none"/></svg>');
  background-size: cover;
  font-size: 15px;
  line-height: 19px;
  margin-right: 5px;
  margin-top: -3px;
}

ul[data-checked="true"] > li:before {
  margin-top: 1px;
}

.ql-editor ul[data-checked="true"] li:before {
  margin-top: -3px;
}

.content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  h1 {
    display: block;
    font-size: 2rem;
    font-weight: bold;
    margin-left: 0;
    margin-right: 0;
    color: inherit;
  }

  h2 {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    color: inherit;
  }

  h3 {
    display: block;
    font-size: 1.17rem;
    font-weight: bold;
    color: inherit;
  }

  h4 {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    color: inherit;
  }

  h5 {
    display: block;
    font-size: 0.83rem;
    font-weight: bold;
    color: inherit;
  }

  h6 {
    display: block;
    font-size: 0.67rem;
    font-weight: bold;
    color: inherit;
  }

  a {
    color: #3eb9ff;
    text-decoration: underline;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
      list-9;
    counter-increment: list-num;
  }
}
</style>
