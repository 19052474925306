<template>
  <v-container v-if="user">
    <v-row>
      <v-col col="6">
        <UserAvatar :user="user" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="tw-font-semibold tw-text-lg tw-text-charcoal tw-mb-0">
          {{ user.fullName }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p
          class="tw-text-light-grey tw-font-bold tw-text-xs tw-tracking-wider tw-text-left tw-mb-0"
        >
          ACCOUNT SETTINGS
        </p>
      </v-col>
    </v-row>
    <!--Edit Profile-->
    <v-row>
      <v-col class="pb-1">
        <div class="tw-border-solid tw-border-warm-grey tw-border-b tw-pb-3">
          <router-link :to="{ name: 'EditProfile' }" class="tw-text-black">
            <div class="tw-flex tw-flex-row tw-items-center tw-justify-start">
              <img
                src="@/assets/svgs/profile/profile.svg"
                alt="Profile"
                class="tw-mr-5"
              />
              <span>Edit Profile</span>
              <img
                src="@/assets/svgs/profile/arrow.svg"
                alt="Arrow Right"
                class="tw-ml-auto tw-block"
              />
            </div>
          </router-link>
        </div>
      </v-col>
    </v-row>
    <!--Change Password-->
    <v-row>
      <v-col class="pb-1">
        <div class="tw-border-solid tw-border-warm-grey tw-border-b tw-pb-3">
          <router-link :to="{ name: 'ChangePassword' }" class="tw-text-black">
            <div class="tw-flex tw-flex-row tw-items-center tw-justify-start">
              <img
                src="@/assets/svgs/profile/password.svg"
                alt="Password"
                class="tw-mr-5"
              />
              <span>Change Password</span>
              <img
                src="@/assets/svgs/profile/arrow.svg"
                alt="Arrow Right"
                class="tw-ml-auto tw-block"
              />
            </div>
          </router-link>
        </div>
      </v-col>
    </v-row>
    <!--Delete Account-->

    <!--Notifications-->
    <v-row>
      <v-col class="pb-1">
        <div class="tw-border-solid tw-border-warm-grey tw-pb-3">
          <router-link :to="{ name: 'Notifications' }" class="tw-text-black">
            <div class="tw-flex tw-flex-row tw-items-center tw-justify-start">
              <img
                src="@/assets/svgs/profile/notifications.svg"
                alt="Delete Account"
                class="tw-mr-5"
              />
              <span>Notifications</span>
              <img
                src="@/assets/svgs/profile/arrow.svg"
                alt="Arrow Right"
                class="tw-ml-auto tw-block"
              />
            </div>
          </router-link>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p
          class="tw-text-light-grey tw-font-bold tw-text-xs tw-tracking-wider tw-text-left tw-mb-0"
        >
          TRIP UPGRADES
        </p>
      </v-col>
    </v-row>
    <!--Available Upgrades-->
    <v-row v-if="user.user_wallet">
      <v-col class="pb-1">
        <div class="tw-border-solid tw-border-warm-grey tw-border-b tw-pb-3">
          <router-link
            :to="{ name: 'AvailableUpgrades' }"
            class="tw-text-black"
          >
            <div class="tw-flex tw-flex-row tw-items-center tw-justify-start">
              <img
                src="@/assets/svgs/profile/profile-diamond.svg"
                alt="Profile"
                class="tw-mr-5"
              />
              <span>Available Upgrades</span>
              <img
                src="@/assets/svgs/profile/arrow.svg"
                alt="Arrow Right"
                class="tw-ml-auto tw-block"
              />
            </div>
          </router-link>
        </div>
      </v-col>
    </v-row>
    <!--Purchase History-->
    <v-row>
      <v-col class="pb-1">
        <div class="tw-border-solid tw-border-warm-grey tw-pb-3">
          <router-link :to="{ name: 'PurchaseHistory' }" class="tw-text-black">
            <div class="tw-flex tw-flex-row tw-items-center tw-justify-start">
              <img
                src="@/assets/svgs/profile/profile-purchase-history.svg"
                alt="Profile"
                class="tw-mr-5"
              />
              <span>Purchase History</span>
              <img
                src="@/assets/svgs/profile/arrow.svg"
                alt="Arrow Right"
                class="tw-ml-auto tw-block"
              />
            </div>
          </router-link>
        </div>
      </v-col>
    </v-row>
    <div>
      <button class="btn-tertiary tw-mt-10" @click="logout">Log out</button>
    </div>
  </v-container>
</template>
<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";

export default {
  name: "ProfileHome",
  props: ["user"],
  components: {
    UserAvatar
  },
  data() {
    return {
      receiveSMS: this.user.receiveSMS,
      receiveEmails: this.user.receiveEmails,
      receiveMarketingEmails: this.user.receiveMarketingEmails
    };
  },
  methods: {
    async saveNotificationSetting(property) {
      const updatedUser = {
        [property]: this[property]
      };
      await this.$store.dispatch("users/patch", [this.user.id, updatedUser]);
    },
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push("/auth");
    }
  },
  beforeMount() {
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: false,
      text: null,
      method: null
    });

    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Profile",
      bgColor: "#6A4AE8",
      fontColor: "#FFFFFF",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
  }
};
</script>
