<template>
  <a :href="link" target="_BLANK">
    <v-col>
      <div class="partner-block">
        <v-row class="text-left" dense align="center">
          <v-col cols="2">
            <img src="@/assets/svgs/partners/faye-logo.svg" />
          </v-col>
          <v-col cols="8" class="pl-3">
            <h4 class="tw-font-figtree tw-font-bold">Protect Your Trip</h4>
            <p class="tw-text-sm mb-0">Award-winning travel insurance  you’d write home about.</p>
          </v-col>
          <v-col cols="2" class="text-center">
            <img src="@/assets/svgs/arrow-click.svg" />
          </v-col>
        </v-row>
      </div>
    </v-col>
  </a>
</template>

<script>
export default {
  name: 'FayeButton',

  props: [
    'link'
  ]
}
</script>

<style lang="scss" scoped>
.partner-block {
  padding: 13px 10px;
  border-radius: 3px;
  background-color: #fafafa;
  box-shadow: 0px 2px 3px rgba(0,0,0,0.2);
  cursor: pointer;

  h4 {
    color: #203848;
  }

  p {
    font-size: 12px;
    line-height: 1.1;
    color: #707070;
  }
}
</style>